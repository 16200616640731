// header
.header {
	z-index: 998;
	position: sticky;
	top: 0;
	left: 0;
	right: 0;
	background: $green-darker;
	box-shadow: $shadow;
	transition: 0.5s;

	.container,
	.container-fluid {
		.container-holder {
			>.column {
				padding-right: 0;
				align-items: stretch;
				justify-content: flex-end;

				@include media-breakpoint-down(lg) {
					flex-flow: row wrap;
				}

				@extend .navbar-expand-xl;
			}
		}
	}

	// logo
	.logo {
		z-index: 100;
		position: absolute;
		top: 0;
		left: 15px;
		width: 160px;
		margin: 0;
		transition: 0.5s;
		filter: drop-shadow(0 0 20px rgba($black, 0.15));

		@include media-breakpoint-up(md) {
			width: 210px;
		}

		@include media-breakpoint-up(xl) {
			width: 260px;
			left: 40px;
		}

		a {
			@extend .d-block;

			img {
				@extend .w-100;
			}
		}
	}

	// menu-toggle
	.menu-toggle {
		margin: 0 15px 0 auto;

		.navbar-toggler {
			display: flex;
			align-items: center;
			height: 100%;
			padding: 0;
			border: none;
			transition: 0.5s;

			.navbar-toggler-icon {
				width: 30px;
				height: 30px;
				background-color: $white;
				border-radius: 100%;
				color: $green-darker;
				font-size: 18px;
				line-height: 30px;
				text-align: center;
			}

			.navbar-toggler-label {
				margin-left: 10px;
				color: $white;
				font-size: 14px;
			}

			&[aria-expanded="true"] {
				i {
					&::before {
						content: "\f00d";
					}
				}
			}

			&:hover {
				color: $green;
			}
		}
	}

	// menu
	.menu {
		@include media-breakpoint-down(lg) {
			order: 5;

			.navbar-nav {
				padding-top: 20px;

				>.nav-item {
					border-top: 1px solid rgba($white, 0.25);

					>.nav-link {
						display: flex;
						align-items: center;
						color: $white;
						font-size: 16px;
						font-weight: 700;

						&:hover {
							color: $yellow;
						}
					}

					&.active {
						>.nav-link {
							color: $yellow;
						}
					}
				}

				.nav-item {
					.dropdown-menu {
						position: static !important;
						transform: none !important;
						margin: 0 0 0 15px;
						padding: 0;
						border: none;
						box-shadow: none;
						background: transparent;

						.nav-item {
							font-size: 14px;

							.nav-link {
								color: $white;

								&:hover {
									color: $yellow;
								}
							}

							&:first-of-type {
								.nav-link {
									padding-top: 0;
								}
							}

							&.active {
								>.nav-link {
									color: $yellow;
								}
							}
						}
					}
				}
			}
		}

		@include media-breakpoint-up(xl) {
			flex-grow: 0;

			.navbar-nav {
				>.nav-item {
					display: flex;
					align-items: center;
					margin-right: 30px;
					font-size: 14px;

					>.nav-link {
						line-height: 72px;
						padding: 4px 0 0 0 !important;
						color: $white;
						border-bottom: 4px solid transparent;

						&:hover {
							color: $white;
							border-bottom-color: $white;
						}
					}

					&.active {
						>.nav-link {
							color: $white;
							border-bottom-color: $white;
						}
					}
				}

				.nav-item {
					.dropdown-menu {
						padding: 0.5rem;
						background-color: $green-darker;
						border-color: $white;

						&::after {
							border-bottom-color: $green-darker;
						}

						.nav-item {
							font-size: 14px;
							line-height: 1.2;
							white-space: nowrap;

							.nav-link {
								color: $white;

								&:hover {
									color: $orange;
								}
							}

							&.active {
								.nav-link {
									color: $orange;
								}
							}
						}
					}
				}
			}
		}
	}

	// book-button
	div.book-button {
		a {
			display: flex;
			align-items: center;
			height: 50px;
			padding: 0 20px;
			background-color: $red;
			color: $white;
			font-size: 14px;
			font-weight: 700;
			text-decoration: none;

			@include media-breakpoint-up(sm) {
				height: 80px;
				padding: 0 30px;
			}

			&:hover {
				background-color: $orange;
				color: $white;
			}

			i {
				font-size: 22px;

				@include media-breakpoint-up(sm) {
					display: none;
				}
			}

			.list-item-title {
				@include media-breakpoint-down(xs) {
					display: none;
				}
			}
		}
	}
}

&.sticky,
&.navbar-collapse-active {
	.header {
		.logo {
			width: 90px;

			@include media-breakpoint-up(sm) {
				width: 130px;
			}
		}
	}
}

&.faq-overview {
	.header {
		position: absolute;
	}
}
