// bg-*
// =========================================================================
section {

	&.bg-light,
	&.bg-dark {
		margin: 0;
		padding: 6vh 0;
	}
}

.lead-section+.content-section,
.lead-section+.bundle-overview-section {
	margin-top: calc(-6vh + 30px);
}

// intro-section
// =========================================================================
.intro-section {
	position: relative;
	padding-top: calc(6vh + 23px);
	padding-bottom: 6vh;
	background: url('/images/logo-visual.svg') no-repeat center left $green-darker;
	color: $white;

	&::after {
		content: '';
		z-index: 11;
		position: absolute;
		top: 100%;
		left: 0;
		right: 0;
		height: 23px;
		background: url('/images/border-green-dark.svg') repeat-x center bottom;
	}

	h1,
	h2,
	h3,
	h5,
	h6 {
		color: $white;
	}

	h4 {
		color: $orange;
	}

	// collection
	.collection {
		margin-top: 30px;
	}
}

// lead-section
// =========================================================================
.lead-section {
	margin: 6vh 0;

	.subtitle h5 {
		margin-bottom: 0;
		font-size: 36px;
	}

	.container-one-column {
		.container-holder {
			max-width: 750px;
			margin: 0 auto;
			text-align: center;
		}
	}

	// bundles
	.info-nav {
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .align-items-center;
		@extend .justify-content-between;
		margin-bottom: 30px;
	}

	.info {

		// accommodation
		.properties {
			@extend .d-flex;
			@extend .flex-wrap;
			@extend .justify-content-center;
			@extend .list-unstyled;

			li {
				margin: 0 15px;
			}

			.icon {
				margin-right: 5px;
			}
		}

		// assortiment
		.former-price {
			font-size: $font-size-sm;
			text-decoration: line-through;
		}

		.discount-price {
			font-size: $font-size-sm;

			.amount {
				font-size: $font-size-lg;
			}

			margin-bottom: 1rem;
		}

		.price {
			margin-bottom: 1rem;

			.amount {
				font-size: $font-size-lg;
			}
		}

	}
}

// content-section
// =========================================================================
.content-section {
	margin: 6vh 0;
}

// collection-section
// =========================================================================
.collection-section {
	margin: 6vh 0;

	// collection
	.collection {
		.card {
			@extend .default-card !optional;
		}
	}
}

// banner-section
// =========================================================================
.banner-section {
	position: relative;
	overflow: hidden;

	&::before {
		content: '';
		z-index: 10;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		height: 23px;
		background: url('/images/border-white.svg') repeat-x center bottom;
	}

	&::after {
		content: '';
		z-index: 10;
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		height: 23px;
		background: url('/images/border-white.svg') repeat-x center top;
	}

	// owl-carousel
	.owl-carousel {
		height: 75vh;
		min-height: 360px;
		max-height: 720px;

		.item {
			height: 75vh !important; // overide
			min-height: 360px;
			max-height: 720px;
			padding: 0; // overide

			.owl-container {
				z-index: 1;
				position: relative;
				max-width: 960px;

				.owl-caption {
					display: flex;
					align-items: center;
					flex-direction: column;
					text-shadow: none;

					.owl-subtitle {
						order: -1;
						margin: 0;
						font-family: $font-family-tertiary;
						@include font-size($h2-font-size);
					}

					.owl-title {
						margin: 0;
						@include font-size($h1-font-size);
					}
				}
			}
		}

		.owl-nav {

			.owl-prev,
			.owl-next {
				font-size: 24px;

				@include media-breakpoint-up(xl) {
					font-size: 30px;
				}
			}

			.owl-prev {
				@include media-breakpoint-up(xl) {
					left: 25px;
				}
			}

			.owl-next {
				@include media-breakpoint-up(xl) {
					right: 25px;
				}
			}
		}

		.owl-dots {
			display: none;
		}
	}
}

// featured-section
// =========================================================================
.featured-section {
	position: relative;
	padding: 10vh 0;
	background: url('/images/logo-visual.svg') no-repeat top right $orange;
	color: $white;

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		color: $white;
	}

	&::before {
		content: '';
		z-index: 11;
		position: absolute;
		left: 0;
		right: 0;
		bottom: 100%;
		height: 23px;
		background: url('/images/border-orange.svg') repeat-x center top;
	}

	&::after {
		content: '';
		z-index: 11;
		position: absolute;
		top: 100%;
		left: 0;
		right: 0;
		height: 23px;
		background: url('/images/border-orange.svg') repeat-x center bottom;
	}

	// two-columns
	.container-two-columns {
		.container-holder {
			align-items: center;

			.column {
				&.one {
					padding-right: 15px !important;

					.collection {
						transform: rotate(-5deg);

						@include media-breakpoint-down(xs) {
							margin-bottom: 15px;
							transform: rotate(-3deg);
						}
					}
				}

				&.two {
					padding-left: 15px !important;

					.collection {
						transform: rotate(5deg);

						@include media-breakpoint-down(xs) {
							margin-bottom: 15px;
							transform: rotate(3deg);
						}
					}
				}
			}
		}

		&:last-of-type {
			@include media-breakpoint-down(sm) {
				margin-top: 45px;
			}

			.container-holder {
				@include media-breakpoint-down(sm) {
					flex-direction: column-reverse;
				}
			}
		}
	}

	// collection
	.collection {
		&.grid {
			.grid-items {
				margin: 0 !important;

				.item {
					flex: 100% !important;
					max-width: unset !important;
					margin: 0 !important;
					padding: 0 !important;

					.card {
						@extend .default-card;

						.card-caption {
							text-align: center;

							.card-title {
								margin-bottom: 0;
							}
						}
					}
				}
			}
		}
	}
}

// bundle-overview-section
// =========================================================================
.bundle-overview-section {
	margin: 6vh 0;

	// collection
	.collection {
		.card {
			@extend .default-card !optional;
		}
	}
}

// bundle-detail-section
// =========================================================================
.bundle-detail-section {
	margin: 6vh 0;
}

// gallery-section
// =========================================================================
.gallery-section {
	margin-top: calc(-6vh + 30px);
	margin-bottom: calc(-6vh + 30px);
}

// booking-section
// =========================================================================
.booking-section {
	margin: 6vh 0;

	h2 {
		margin-bottom: 30px;
	}
}

// contact-section
// =========================================================================
.contact-section {
	margin: 6vh 0;
}
