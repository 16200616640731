// container
.container {
	&.container-small {
		max-width: 540px;
	}

	&.container-medium {
		max-width: 720px;
	}

	&.container-large {
		max-width: 960px;
	}

	&.container-intro {
		max-width: 960px;
		margin-bottom: 30px;
		text-align: center;
	}

	&.container-outro {
		max-width: 960px;
		margin-top: 30px;
		text-align: center;
	}
}

.container-fluid {
	max-width: 1920px;
}

// headings
h1,
h2 {
	font-style: italic;
	line-height: 1;
}

h4,
h5 {
	color: $orange;
	font-family: $font-family-tertiary;
}

h4+h1 {
	margin-top: -0.5rem;
}

// paragraph
p {
	min-height: 1rem;
}

// img & iframe
img {
	max-width: 100%;
	height: auto;
}

iframe {
	max-width: 100%;
}

// transition
a,
.btn,
.owl-btn,
.card-btn {
	transition: 0.5s;
}

// form
.form {
	padding: 15px;

	@include media-breakpoint-up(xl) {
		padding: 30px;
	}

	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}

// gallery
.gallery,
.gallery-horizontal {
	.gallery-item {
		.gallery-link {
			padding: 5px;
			border-radius: 4px;
			box-shadow: 0 0 20px rgba($black, 0.15);

			.gallery-label {
				position: absolute;
				top: 10px;
				left: 10px;
				@extend .btn;
				@extend .btn-primary;
			}
		}
	}
}

// default-card
.default-card {
	padding: 10px;
	border: none;
	border-radius: 4px;
	box-shadow: 0 0 20px rgba($black, 0.15);

	.card-body {
		.card-caption {
			.card-title-link {
				margin-bottom: 5px;
			}

			.card-title {
				color: $green-darker;
				font-family: $font-family-tertiary;
			}

			.card-subtitle {
				color: $orange;
				font-size: 18px;
			}

			.card-description {
				.list-icons {
					color: $green-darker;

					.list-item {
						.icon {
							i {
								font-weight: 400;
							}
						}
					}
				}
			}
		}
	}

	&[href]:hover {
		.card-title {
			color: $orange;
		}
	}
}

// portrait-collection
.portrait-collection {
	.card {
		@extend .default-card;
		height: auto !important;

		.card-body {
			.card-caption {
				text-align: center;

				.card-title {
					margin-bottom: 0;
				}

				.card-subtitle,
				.card-description {
					display: none;
				}
			}
		}
	}
}

// large-collection
.large-collection {
	&.grid {
		.grid-items {
			@include media-breakpoint-up(xl) {
				margin-left: -25px;
				margin-right: -25px;
			}

			.item {
				@include media-breakpoint-up(lg) {
					@include make-col(6);
				}

				@include media-breakpoint-up(xl) {
					@include make-col(6);
					padding-right: 25px;
					padding-left: 25px;
				}

				&:nth-child(odd) {
					.card {
						transform: rotate(-5deg);

						@include media-breakpoint-down(xs) {
							transform: rotate(-3deg);
						}
					}
				}

				&:nth-child(even) {
					.card {
						margin-top: 8vh;
						transform: rotate(5deg);

						@include media-breakpoint-down(xs) {
							transform: rotate(3deg);
							margin-top: 15px;
						}
					}
				}
			}
		}
	}

	.card {
		@extend .default-card;
		height: auto !important;

		.card-body {
			.card-caption {
				text-align: center;

				.card-title {
					margin-bottom: 0;
				}

				.card-subtitle,
				.card-description {
					display: none;
				}
			}
		}
	}
}
