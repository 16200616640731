// mini-sab
.mini-sab {
	position: relative;
	background-color: $orange;

	@include media-breakpoint-up(lg) {
		z-index: 997;
		position: sticky;
		top: 80px;
		left: 0;
		right: 0;
	}

	&::before {
		content: '';
		z-index: 10;
		position: absolute;
		top: -23px;
		left: 0;
		right: 0;
		height: 23px;
		background: url('/images/border-orange.svg') repeat-x center top;
	}

	&::after {
		content: '';
		z-index: 10;
		position: absolute;
		left: 0;
		right: 0;
		bottom: -23px;
		height: 23px;
		background: url('/images/border-orange.svg') repeat-x center bottom;
	}

	.container {
		position: relative;
		display: flex;
		justify-content: center;

		.container-holder {
			padding: 5px 0 15px 0;
		}
	}

	&.sticky {
		@include media-breakpoint-up(lg) {

			&::after {
				display: none;
			}

			.container {
				max-width: none;
				justify-content: flex-end;
			}
		}
	}
}

&.faq-overview {
	.mini-sab {
		position: relative !important;
		top: auto !important;
	}
}
