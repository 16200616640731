// footer
.footer {
	background-color: $green-darker;
	color: $white;

	@include media-breakpoint-down(sm) {
		text-align: center;
	}

	h1,
	h2,
	h3,
	h6 {
		color: $white;
	}

	// footer-partners
	.footer-partners {
		padding: 6vh 0;
		background-color: $white;

		ul {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: center;
			margin: 0 -15px;
			padding: 0;
			list-style: none;

			li {
				margin: 15px;
			}
		}
	}

	// footer-outro
	.footer-outro {
		padding: 6vh 0;

		// two-columns
		.container-two-columns {
			.container-holder {
				.column {
					&.one {
						padding-right: 15px !important;
						text-align: center;

						@include media-breakpoint-up(md) {
							@include make-col(4);
						}
					}

					&.two {
						padding-left: 15px !important;

						@include media-breakpoint-up(md) {
							@include make-col(7);
						}
					}
				}
			}
		}

		// footer-link (contact)
		.footer-link {
			ul {
				margin: 1rem 0 1.5rem 0;

				@include media-breakpoint-down(sm) {
					justify-content: center;
				}

				li {
					margin: 0 10px 10px 0;

					a {
						display: inline-flex;
						align-items: center;
						justify-content: center;

						@extend .btn;
						@extend .btn-primary;

						&::before {
							margin-right: 10px;
							font-size: 18px;
							font-weight: 900;
							font-family: $font-awesome;
						}

						&[href^="tel:"]::before {
							content: "\f095";
						}

						&[href^="mailto:"]::before {
							content: "\f0e0";
						}

					}
				}
			}
		}

		// footer-logolink (social)
		.footer-logolink {
			ul {
				@include media-breakpoint-down(sm) {
					justify-content: center;
				}

				li {
					margin-right: 5px;

					a {
						display: block;
						width: 36px;
						height: 36px;
						background-color: $white;
						border-radius: 100%;
						color: $green-darker;
						font-size: 20px;
						line-height: 36px;
						text-align: center;

						&:hover {
							background-color: $orange;
							color: $white;
						}
					}
				}
			}
		}

	}

	// footer-copyright
	.footer-copyright {
		padding: 30px 0;

		// two-columns
		.container-two-columns {
			.container-holder {
				.column {
					&.one {
						padding-right: 15px !important;

						@include media-breakpoint-up(md) {
							@include make-col(4);
						}
					}

					&.two {
						padding-left: 15px !important;

						@include media-breakpoint-up(md) {
							@include make-col(7);
						}
					}
				}
			}
		}

		// footer-text
		.footer-text {
			ul {
				display: flex;
				flex-wrap: wrap;
				margin: 0;
				padding: 0;
				list-style: none;

				@include media-breakpoint-down(sm) {
					flex-direction: column;
				}

				li {
					font-size: 12px;

					@include media-breakpoint-up(md) {
						margin-right: 20px;
					}

					a {
						color: #e7ffbb;
						text-decoration: none;

						&:hover {
							text-decoration: underline;
						}
					}
				}
			}
		}
	}
}
