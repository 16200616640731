// eyecatcher
.eyecatcher,
.eyecatcher .owl-carousel .item {
	height: 50vh;
	min-height: 300px;
	max-height: 540px;
}

.eyecatcher.large,
.eyecatcher.large .owl-carousel .item {
	height: 70vh;
	min-height: 300px;
	max-height: 1080px;

	@include media-breakpoint-up(sm) {
		height: 90vh;
	}

	@include media-breakpoint-up(xl) {
		min-height: 540px;
		height: calc(100vh - 200px);
	}
}

// eyecatcher
.eyecatcher {
	position: relative;
	overflow: hidden;

	// owl-carousel
	.owl-carousel {
		.item {
			align-items: flex-end;
			padding-bottom: 40px;

			@include media-breakpoint-up(xl) {
				padding-bottom: 80px;
			}

			.owl-caption-holder {
				z-index: 100;

				.owl-container {
					max-width: 960px;

					.owl-caption {
						text-shadow: none;

						.owl-title {
							font-weight: 400;
							font-style: normal;
							font-family: $font-family-tertiary;
							@include font-size($h1-font-size);

							@include media-breakpoint-up(xl) {
								font-size: 60px;
							}
						}
					}
				}
			}
		}
	}

	.owl-nav {

		.owl-prev,
		.owl-next {
			font-size: 24px;

			@include media-breakpoint-up(xl) {
				font-size: 30px;
			}
		}

		.owl-prev {
			@include media-breakpoint-up(xl) {
				left: 25px;
			}
		}

		.owl-next {
			@include media-breakpoint-up(xl) {
				right: 25px;
			}
		}
	}

	.owl-dots {
		display: none;
	}
}

&.home {
	.eyecatcher {
		.owl-carousel {
			.item {
				&::after {
					content: "";
					position: absolute;
					left: 0;
					right: 0;
					bottom: 0;
					height: 50%;
					background: linear-gradient(180deg, rgba($black, 0) 0%, rgba($black, 0.5) 100%);
				}
			}
		}
	}
}
